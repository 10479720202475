import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LocalstorageService} from './local-storage.service';
import {environment} from '../../../environments/environment';
import {JWT_TOKEN, REFRESH_TOKEN} from '../models/const';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private API = environment.privateApi + '/user';
    headers = new HttpHeaders().set('Content-Type', 'application/json');
    user: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    getUserObservable(): Observable<any> {
        return this.user.asObservable();
    }

    constructor(
        private http: HttpClient,
        public localStorage: LocalstorageService,
    ) {
    }


    doLogout() {

      return this.http.get<any>(`${this.API}/logout`).pipe(
          map((res: Response) => {
            this.removeTokens();
          }),
          catchError(err => this.handleError(err))
        );
    }

    // User profile
    getUserProfile(id): Observable<any> {
        const api = `${this.API}/user-profile/${id}`;
        return this.http.get(api, {headers: this.headers}).pipe(
            map((res: Response) => {
                return res || {};
            }),
            catchError(this.handleError)
        );
    }

    getUser() {
        return this.http.get<any>(`${this.API}/me`).subscribe(value => {
            console.log('my user', value);
            this.user.next(value);
        }, error => {
            catchError(this.handleError);
        });
    }

    public removeTokens() {
        this.localStorage.removeItem(JWT_TOKEN);
        this.localStorage.removeItem(REFRESH_TOKEN);
    }

    // Error
    handleError(error: HttpErrorResponse) {

      let msg = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            msg = error.error.message;
        } else {
            // server-side error
            msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(msg);
    }
}
