import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, map, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {Jwt} from '../models/jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService,
              public userService: UserService) {
  }


  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.isLoggedIn) {
      request = this.addToken(request, this.authService.getToken());
    }

    return next.handle(request).pipe(
      map(event => {

        if (event instanceof HttpResponse) {

        }
        return event;
      }),
      catchError(error => {

        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 417) {
          this.userService.removeTokens();
          return throwError(error);
        } else {
          return throwError(error);

        }
      }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  addLang(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'Accept-Language': 'fr'
      }
    });
  }

  private deleteToken(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.delete('Authorization')

    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          console.log('token token', token);
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(request, token.accessToken));
        }), catchError(error => {
          console.log('error--->>>', error);
          if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 417)) {
            console.log('interceptor remove token');
            this.userService.removeTokens();
          }
          return throwError(error);
        })
        ,
        finalize(() => {
          this.isRefreshing = false;
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((jwt: Jwt) => {
          return next.handle(this.addToken(request, jwt.accessToken));
        }));
    }
  }
}
