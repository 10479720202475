import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'general-menu',
        title    : 'General menu',
        translate: 'NAV.GENERAL_MENU',
        type     : 'group',
        children : [
            {
                id       : 'catalog',
                title    : 'Catalog',
                translate: 'NAV.CONFIGURATIONS.TITLE',
                type     : 'collapsable',
                icon     : 'shopping_cart',
                children : [
                    {
                        id       : 'product-management',
                        title    : 'Product',
                        translate: 'NAV.PRODUCT_MANAGEMENT.TITLE',
                        type     : 'item',
                        url      : '/products',
                    },
                    {

                        id       : 'category-config',
                        title    : 'Category & Sub Category',
                        translate: 'NAV.CATEGORY_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'configs/category',
                    },
                    {
                        id       : 'color-config',
                        title    : 'Colors',
                        translate: 'NAV.COLOR_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'configs/color',
                    },
                    {
                        id       : 'discount-config',
                        title    : 'Discounts',
                        translate: 'NAV.COLOR_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'configs/discount',
                    },
                    {
                        id       : 'coupon-config',
                        title    : 'Coupons',
                        translate: 'NAV.COUPON_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'configs/coupon',
                    }
                ]
            },
            {
                id       : 'orders',
                title    : 'Orders',
                translate: 'NAV.CONFIGURATIONS.TITLE',
                type     : 'collapsable',
                icon     : 'payment',
                children : [
                    {
                        id       : 'order-management',
                        title    : 'Orders',
                        translate: 'NAV.PRODUCT_MANAGEMENT.TITLE',
                        type     : 'item',
                        url      : 'orders',
                    },
                    {

                        id       : 'invoice-config',
                        title    : 'Invoices',
                        translate: 'NAV.CATEGORY_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'invoices',
                    },
                    {
                        id       : 'returns-config',
                        title    : 'Returns',
                        translate: 'NAV.COLOR_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'returns',
                    },
                    {
                        id       : 'delivery-config',
                        title    : 'Delivery',
                        translate: 'NAV.COLOR_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'delivery',
                    },
                    {
                        id       : 'cart-config',
                        title    : 'Shopping Carts',
                        translate: 'NAV.COLOR_CONFIG.TITLE',
                        type     : 'item',
                        url      : 'carts',
                    }
                ]
            },
            {
                id       : 'customers',
                title    : 'Customers',
                translate: 'NAV.CONFIGURATIONS.TITLE',
                type     : 'item',
                icon     : 'account_circle',
                url      : 'customer'
            }
        ],
    }
];
