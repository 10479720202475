import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {AuthGuard} from './shared/guard/auth.guard';
import {AuthInterceptor} from './shared/interceptor/authconfig.interceptor';

const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import( './login/login.module' ).then( m => m.LoginModule )
    },
    {
        path: 'configs',
        loadChildren: () => import('./main/configurations/configurations.module').then(m => m.ConfigurationsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'products',
        loadChildren: () => import('./main/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'orders',
        loadChildren: () => import('./main/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'carts',
        loadChildren: () => import('./main/carts/carts.module').then(m => m.CartsModule),
        canActivate: [AuthGuard]
    },
    {
        path      : '**',
        redirectTo: 'orders'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            anchorScrolling: 'disabled',
            scrollPositionRestoration: 'top',
            initialNavigation: 'enabled' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }],
})
export class AppModule {
}
