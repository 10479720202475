import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LocalstorageService} from './local-storage.service';
import {JWT_TOKEN, REFRESH_TOKEN} from '../models/const';
import {Jwt} from '../models/jwt';
import {UserService} from './user.service';
import {UserRequest} from '../models/user-request';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private API =  environment.publicApi + '/auth';
    headers = new HttpHeaders().set('Content-Type', 'application/json');



    constructor(
        private http: HttpClient,
        public router: Router,
        public route: ActivatedRoute,
        public localStorage: LocalstorageService,
        public userService: UserService,
    ) {
    }


    // Sign-in
    signIn(user: UserRequest) {
        return this.http.post<any>(`${this.API}/login-admin`, user)
          .pipe(
            map((res: Jwt) => {
              this.storeTokens(res);
              this.userService.getUser();
            }),
            catchError(err => this.handleError(err))

          )
    }








    getToken() {
        return this.localStorage.getItem(JWT_TOKEN);
    }

    getRefreshToken() {
        return this.localStorage.getItem(REFRESH_TOKEN);
    }


    private storeTokens(tokens: Jwt) {
        this.localStorage.setItem(JWT_TOKEN, tokens.accessToken);
        this.localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
    }


    get isLoggedIn(): boolean {
        const authToken = this.localStorage.getItem(JWT_TOKEN);
        return (authToken != null && authToken != undefined);
    }


    refreshToken() {
        return this.http.post<any>(`${this.API}/refresh`, {
            refreshToken: this.getRefreshToken()
        }).pipe(tap((tokens: Jwt) => {
            this.storeTokens(tokens);
        }));
    }

    // Error
    handleError(error: HttpErrorResponse) {
        let msg = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            msg = error.error.message;
        } else {
            // server-side error
            msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
           // this.toastrService.error(error.error.data);
        }
      return throwError(msg);
    }




}
