export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'GENERAL_MENU': 'General menu',
            'CONFIGURATIONS': {
                'TITLE': 'Configurations'
            },
            'CATEGORY_CONFIG': {
                'TITLE': 'Category config.'
            },
            'COLOR_CONFIG': {
                'TITLE': 'Color config.'
            },
            'COUPON_CONFIG': {
                'TITLE': 'Coupon config.'
            },
            'ITEM_MANAGEMENT': {
                'TITLE': 'Item Management'
            },
            'PRODUCT_MANAGEMENT': {
                'TITLE': 'Product Management'
            }
        }
    }
};
